body {
  margin     : 0;
  padding    : 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Own CSS */

nav img {
  max-height: 80px;
}

nav .uk-logo {
  margin-left: -45px;
}

.map-container img {
  max-height: 400px;
}

.uk-button-primary {
  background-color: #1f3566!important;
}

.uk-link, .uk-pagination>.uk-active>*, .uk-text-danger, .uk-alert-danger {
  color: #e42113!important;
}